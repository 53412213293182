.BebasNeuePro_h1_red {
  font-family: "bebas-neue-pro";
  font-size: 180px;
  font-weight: 800;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--naow-red);
}
.BebasNeuePro_h1_light {
  font-family: "bebas-neue-pro";
  font-size: 180px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(20, 47, 67, 0.03);
}
.BebasNeuePro_h2_red {
  font-family: "bebas-neue-pro";
  font-size: 140px;
  font-weight: 800;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--naow-red);
}
.BebasNeuePro_h2_light {
  font-family: "bebas-neue-pro";
  font-size: 140px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(20, 47, 67, 0.05);
}
.BebasNeuePro_h3_light {
  font-family: "bebas-neue-pro";
  font-size: 130px;
  font-weight: 800;
  font-stretch: expanded;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  color: var(--white);
}
.BebasNeuePro_h4_light {
  font-family: "bebas-neue-pro";
  font-size: 100px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.BebasNeuePro_subtitle {
  font-family: "bebas-neue-pro";
  font-size: 25px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: 2.5px;
  color: var(--white);
}
.BebasNeuePro_button_light {
  font-family: "bebas-neue-pro";
  font-size: 23px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.BebasNeuePro_button_dark {
  font-family: "bebas-neue-pro";
  font-size: 23px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
}
.BebasNeuePro_p_thin_light {
  font-family: "bebas-neue-pro";
  font-size: 22px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
  color: var(--white);
}

.BebasNeuePro_p_thin_light p {
  font-family: "bebas-neue-pro";
  font-size: 22px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
  color: var(--white);
}

.BebasNeuePro_p_dark {
  font-family: "bebas-neue-pro";
  font-size: 22px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
}
.BebasNeuePro_p_light {
  font-family: "bebas-neue-pro";
  font-size: 20px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--white);
}
.BebasNeuePro_h5_light {
  font-family: "bebas-neue-pro";
  font-size: 15px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--white);
}
.BebasNeuePro_h5_dark {
  font-family: "bebas-neue-pro";
  font-size: 15px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
}
.BebasNeuePro_footer {
  font-family: "bebas-neue-pro";
  font-size: 15px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
}
