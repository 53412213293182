#feature {
  position: relative;
}

section {
  position: relative;
  height: 100vh;
}

#feature-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#feature-header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
}

#feature-flagship-headline-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 62%;
  pointer-events: none;
}

#feature-footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#feature-scroll-indicator {
  position: fixed;
  right: 20px;
  top: 40%;
  height: 20%;
  display: flex;
  align-items: center;
}

#feature-email {
  position: fixed;
  left: 20px;
  top: 40%;
  height: 20%;
  display: flex;
  align-items: center;
  margin-left: -42px;
}

#feature-email:hover p {
  color: var(--naow_red);
}

#feature h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 11vmin;
  line-height: 11vmin;
  /* margin-bottom: 12px; */
  text-transform: uppercase;
  font-weight: 900;
}

.copy-block {
  opacity: 0;
}

.active {
  opacity: 1;
}

#feature-cut {
  position: absolute;
  flex-direction: row-reverse;
  width: 100%;
  bottom: 73%;
  pointer-events: all;
  z-index: 99;
}

#feature-cut-circle {
  width: 60px;
  height: 60px;
  background-color: var(--naow_red);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  box-shadow: -5px 5px 20px #0000001a;
}

#feature-cut-line {
  height: 1px;
  margin-left: -30px;
  width: 25%;
  min-width: 200px;
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 16px 1px;
  background-repeat: repeat-x;
  /* border-bottom: dashed 2px grey; */
  /* background-color: grey; */
}

#feature-cut-arrow-line {
  width: 12px;
  height: 2px;
  background-color: white;
}

#feature-cut-arrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 8px solid white;
  border-bottom: 6px solid transparent;
}

#mobile-scroll-indicator {
  position: fixed;
  bottom: 24px;
  left: calc(50% - 6px);
}

@media only screen and (min-width: 1064px) {
  #feature {
    /* defines the stroll intensity needed */
    /* height: 500vh; */
  }
  #feature h1 {
    font-size: 12vmin;
    line-height: 12vmin;
  }
  #feature-cut-line {
    margin-left: -40px;
  }
  #feature-cut-circle {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  #feature-cut-arrow-line {
    width: 18px;
    height: 2px;
  }
  #feature-flagship-headline-container {
    top: 70%;
    margin-bottom: unset;
  }
}
