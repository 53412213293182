#references {
  height: 300vh;
}

#references-headline-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 95vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

#reference-headline {
  display: flex;
  flex-direction: column;
  text-shadow: 10px 10px 80px #00000015;
  transition: 0.2s;
  margin-bottom: 1vh;
  margin-top: 1vh;
  cursor: pointer;
  pointer-events: all;
}

#references-canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: -1; */
}

#reference-headline:hover {
  transform: rotate(-2deg);
}

#reference-headline h2 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 10vw;
  height: 10vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 900;
}

#build {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  left: 0;
  right: 0;
}

#feature-email {
  z-index: 2;
}

#feature-email:hover p {
  color: var(--naow_red);
}

#build-appointment {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
}

#build-learn-more {
  align-self: center;
  width: 100%;
  pointer-events: all;
}

#references-back-button {
  position: absolute;
  top: 60px;
  left: 32px;
  z-index: 3;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#references-back-button #feature-cut-circle {
  width: 40px;
  height: 40px;
  transform: rotate(270deg);
  background-color: var(--naow_dark_blue);
}

#references-back-button #feature-cut-arrow-line {
  width: 8px;
}

#references-back-button #feature-cut-arrow {
  border-top: 4px solid transparent;
  border-left: 6px solid white;
  border-bottom: 4px solid transparent;
}

#references-back-button p {
  margin-top: 4px;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #00000011;
}

#build p {
  letter-spacing: 1.5px;
}

@media only screen and (max-height: 650px) {
  #build p {
    font-size: 17px;
  }

  #build #book-appointment-button p {
    font-size: 18px;
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  #references-container {
    position: fixed;
    left: 0;
    right: 0;
  }

  #build {
    position: absolute;
    bottom: 0;
  }

  #build #book-appointment-button {
    margin-bottom: 24px;
  }

  #build-learn-more {
    margin-bottom: 3vh;
  }

  #build-learn-more p {
    padding-left: 32px;
    padding-right: 32px;
  }

  #keynote-underline-wrapper {
    padding: 16px;
  }
}

@media only screen and (min-width: 768px) {
  #references-headline-container {
    height: 100vh;
  }

  #references-back-button {
    top: 80px;
    left: 68px;
  }

  #references-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #build {
    position: absolute;
    justify-content: space-around;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
  }
  #build-appointment {
    width: 50%;
    grid-row: 2;
    grid-column: 2 / 3;
    min-width: 200px;
    width: 15vw;
    max-width: 200px;
  }

  #build-learn-more {
    margin-top: 36px;
    grid-row: 3;
    grid-column: 3 / 6;
  }

  #reference-headline {
    flex-direction: row;
    margin-bottom: 2vh;
    margin-top: 2vh;
  }

  #reference-headline h2 {
    font-size: 5vw;
    height: 5vw;
    margin-right: 16px;
  }
  #keynote-underline-wrapper {
    padding: 24px;
  }
}
