#touch-intro {
  position: relative;
  height: 150vh;
  pointer-events: all;
}

#touch-intro-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#touch-intro-container h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 14vw;
  height: 14vw;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
}

#touch-intro-button {
  pointer-events: all;
  margin-top: 8vh;
  border-radius: 48px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  background: var(--naow_red);
  border-radius: 20px;
}

#intro-swipe {
  position: fixed;
  bottom: 48px;
  width: 60px;
  height: 60px;
  left: calc(50% - 30px);
}
