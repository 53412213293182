#intro {
  background-color: var(--beige);
  pointer-events: all;
  overflow: none;
}

#intro-container {
  width: 90%;
  overflow: none;
}
#intro-container h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 10vw;
  height: 10vw;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
}

#intro-button {
  pointer-events: all;
  margin-top: 8vh;
  border-radius: 48px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
  background: var(--naow_red);
  border-radius: 16px;
  box-shadow: -5px 5px 25px #00000026;
  background-image: url("/src/icons//naow_logo_backdrop.svg");
  background-size: 92px;
  background-repeat: no-repeat;
  background-position: center -8px;
}
