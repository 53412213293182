#showcases {
  background-color: var(--beige);
}

#experiences-container {
  display: grid;
  grid-template-columns: 80%;
  row-gap: 16px;
  column-gap: 32px;
}

#showcase-headers {
  mask-image: radial-gradient(
    closest-side,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0.25)
  );
}

#showcase-headers h2:hover {
  -webkit-text-fill-color: unset !important;
  -webkit-text-stroke: unset !important;
  color: var(--naow_red) !important;
  font-style: italic !important;
}

#showcase-discover {
  position: absolute;
  top: 95%;
  width: 200px;
  text-align: center;
  left: calc(50% - 100px);
}

#showcase-gradient {
  height: 5vh;
  margin-top: -5vh;
  background-image: linear-gradient(to top, transparent, var(--beige));
}

#showcases-header-top {
  position: absolute;
  top: 40px;
  left: 80px;
  z-index: 2;
  display: flex;
  width: 180px;
  justify-content: space-between;
}

#showcase-discover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#showcase-discover p,
#showcases-header-top p {
  font-family: "bebas-neue-pro";
  font-weight: bold;
}

#showcase-line-down {
  width: 1px;
  height: 32px;
  background-color: var(--naow_dark_blue);
}

@media only screen and (min-width: 768px) {
  #experiences-container {
    grid-template-columns: 40% 40%;
  }
}

@media only screen and (min-width: 1024px) {
  #experiences-container {
    grid-template-columns: 25% 25% 25%;
  }
}
