#modal-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: #00000033;
  z-index: 1000;
  backdrop-filter: blur(4px);
}
