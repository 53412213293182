.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000022;
  z-index: 1000;
  backdrop-filter: blur(2px);
  overflow: hidden;
}

.overlay-container iframe {
  background-color: white;
  position: relative;
  width: 920px;
  max-width: 80vw;
  height: 746px;
  max-height: 90vh;
  border-radius: 16px;
  border: 0;
  box-shadow: 0px 8px 30px #00000055;
}

.overlay .overlay-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
