#talk-naow-button {
  height: 100%;
  width: 100px;
  margin-right: 5vw;
  margin-left: 5vw;
  align-items: center;
  background-color: var(--naow_red);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: 0.5s;
  pointer-events: all;
  box-shadow: -5px 5px 25px #00000026;
  background-image: url("/src/icons//naow_logo_backdrop.svg");
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: center -10px;
}

#talk-naow-button h3 {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0px;
}

#talk-naow-extended {
  max-height: 0px;
  opacity: 0;
  padding-left: 16px;
  padding-right: 16px;
  transition: 0.5s;
}

#talk-naow-extended.expanded {
  max-height: 2000px;
  padding-bottom: 32px;
  opacity: 1;
}

#talk-naow-extended div {
  opacity: 0;
  transition: 0.15s;
  transition-delay: 0s;
}

#talk-naow-extended.expanded div {
  transition: 0.3s;
  transition-delay: 0.25s;
  opacity: 1;
}

#talk-naow-text {
  padding-top: 16px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 32px;
}

#talk-naow-text p {
  margin-top: 8px;
  color: white;
}

#book-appointment-button {
  width: 80%;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--naow_dark_blue);
}

#book-appointment-button p {
  padding: 20px;
  text-transform: uppercase;
}

#talk-naow-mailto {
  margin-top: 32px;
}

#talk-naow-line {
  width: 1px;
  height: 24px;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  #talk-naow-text p {
    text-overflow: hidden;
    white-space: nowrap;
  }
  #book-appointment-button p {
    text-overflow: hidden;
    white-space: nowrap;
  }
  #talk-naow-button.expanded {
    width: 90vw;
  }
}

@media only screen and (min-width: 768px) {
  #talk-naow-button {
    width: 230px;
    margin-right: 16px;
    margin-left: 16px;
  }

  #talk-naow-button h3 {
    padding: 32px;
    margin: 0px;
  }

  #talk-naow-extended {
    padding-left: 24px;
    padding-right: 24px;
  }
  #book-appointment-button {
    width: 150%;
  }
  #talk-naow-mailto p {
    font-size: 18px;
  }
  #talk-naow-mailto:hover p {
    color: var(--naow_dark_blue);
  }
}
