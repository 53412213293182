.full-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.gone {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.no-overflow {
  overflow: hidden;
}

.abs {
  position: absolute;
}

.zindex-2 {
  z-index: 2;
}

.zindex-10 {
  z-index: 10;
}

.button {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-10 {
  flex: 0 0 10%;
}

.flex-20 {
  flex: 0 0 20%;
}

.flex-25 {
  flex: 0 0 25%;
}

.flex-30 {
  flex: 0 0 30%;
}

.flex-35 {
  flex: 0 0 35%;
}

.flex-40 {
  flex: 0 0 40%;
}

.flex-50 {
  flex: 0 0 50%;
}

.flex-60 {
  flex: 0 0 60%;
}

.flex-65 {
  flex: 0 0 65%;
}

.flex-70 {
  flex: 0 0 70%;
}

.flex-75 {
  flex: 0 0 75%;
}

.flex-80 {
  flex: 0 0 80%;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: flex-end;
}

.closed {
  margin-left: -101% !important;
}

.width-0 {
  width: 0px;
}

.width-unset {
  width: unset;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.padding-16 {
  padding: 16px;
}

.red {
  background-color: red;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.uppercase {
  text-transform: uppercase;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  cursor: default;
}

/* ANIMATIONS */

.animated-25 {
  transition: 0.25s;
}

.animated-50 {
  transition: 0.5s;
}

.animated-100 {
  transition: 1s;
}

.fadeIn {
  opacity: 1;
  transition: 0.5s;
}

.fadeOut {
  opacity: 0;
  transition: 0.5s;
}

.fadeOut1s {
  opacity: 0;
  transition: 1s;
}

.transitionToOrigin {
  transform: translate(0%);
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.move-left {
  transform: translateX(-250%);
}

.move-right {
  transform: translateX(250%);
}
