#studio-page {
  position: relative;
  background-color: var(--naow_dark_blue);
  width: 100%;
  overflow-y: scroll;
  z-index: 2;
  overflow-x: hidden;
}

#studio-intro {
  position: relative;
  height: 100vh;
}

#studio-main-headline {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 44vh;
}

#studio-find-out {
  position: absolute;
  width: 100%;
  top: 82vh;
  /* bottom: env(safe-area-inset-bottom, 180px); not working with safari 15*/
  display: flex;
  flex-direction: column;
  align-items: center;
}

#studio-page h1 {
  font-family: var(--font-family-bebas-neue-pro);
  margin-left: 8px;
  margin-right: 8px;
  font-size: 9vh;
  height: 10vh;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 900;
}

#studio-underlined {
  text-decoration: underline;
  text-decoration-color: var(--naow_red);
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.studio-white {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

#studio-naow {
  color: white;
}

#studio-page p {
  color: white;
}

#down-stroke {
  margin-top: 8px;
  height: 24px;
  width: 1px;
  background-color: white;
}

#studio-header {
  position: fixed;
  width: 100%;
  top: 0;
}

.studio-page-lottie {
  width: 120vw;
}

#info-container {
  text-align: center;
}

.info-container-background {
  padding: 24px;
  background-image: url("/src/icons/ic_separator_studio.svg");
  min-height: 280px;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

#studio-info p,
#studio-info-trinity p {
  width: 100%;
  margin-bottom: 8px;
}

#studio-info-trinity svg {
  margin-top: -10vh;
  margin-bottom: -10vh;
}

#studio-line-down {
  margin-top: 8vh;
  height: 50vh;
  width: 1px;
  background-color: var(--naow_red);
}

#arrow-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 8px solid var(--naow_red);
}

#studio-flagship-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

#studio-end {
  margin-top: 8vh;
  margin-bottom: 8vh;
}

#studio-flagship-explained {
  background-image: url("/src/icons/ic_separator_studio.svg");
  background-size: 160px;
  background-repeat: no-repeat;
  background-position: left;
  text-align: left;
  margin-top: -40px;
  padding-left: 80px;
  padding-top: 40px;
  padding-bottom: 120px;
}

#studio-line-horizontal {
  margin-top: 8vh;
  margin-bottom: 8px;
  width: 50px;
  height: 1px;
  background-color: var(--naow_red);
}

#studio-team {
  background-color: var(--background);
}

#studio-team svg {
  margin-left: -1px;
  margin-top: -1px;
}

#studio-end-headline-part1 {
  display: flex;
}

@media only screen and (max-width: 768px) {
  #studio-main-headline {
    flex-direction: column;
    align-items: center;
    width: 90%;
    left: 5%;
    top: 33%;
  }
  #info-container {
    padding: 24px;
  }
  #studio-page p {
    font-size: 20px;
  }
  #studio-end-headline-part1 {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  #studio-find-out {
    top: unset;
    bottom: 8px;
  }

  #studio-page h1 {
    font-size: 8vmin;
    height: 8vmin;
  }

  #studio-info p,
  #studio-info-trinity p {
    width: 60%;
  }
  .info-container-background {
    padding: 48px;
  }

  .studio-page-lottie {
    width: 50vw;
  }
  #studio-info,
  #studio-info-trinity {
    margin-top: 10vh;
  }
  #studio-underlined {
    text-underline-offset: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  #studio-flagship-container {
    flex-direction: row;
    width: 60%;
  }
  #studio-page h1 {
    font-size: 12vmin;
    height: 12vmin;
  }
}
