#web-exp-container {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

#web-exp-image-container {
  height: 30vh;
  background-color: #f1f1f1;
}

#web-exp-image {
  max-height: 20vh;
  max-width: 80%;
  box-shadow: 3px 3px 16px #00000022;
  margin: 8%;
}

#web-exp-overview {
  padding: 24px;
  padding-top: 0px;
  background-color: #f1f1f1;
}

#web-exp-details {
  text-align: start;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
}

#euro-symbol {
  background-color: #d9d9d9;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  margin-right: 8px;
}

#web-exp-overview p {
  font-family: "bebas-neue-pro";
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

#euro-symbol p {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

#web-exp-visit-site {
  border-radius: 100px;
  cursor: pointer;
  background-color: var(--naow_red);
}

#web-exp-visit-site h4 {
  font-family: "bebas-neue-pro";
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

#web-exp-details h3 {
  font-family: "bebas-neue-pro";
  margin-bottom: 8px;
}

#web-exp-details p {
  font-family: "bebas-neue-pro";
  font-size: 16px;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 24px;
}

#web-exp-divider {
  height: 1px;
  background-color: whitesmoke;
}
