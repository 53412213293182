#text-underline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

#text-underline-button {
  cursor: pointer;
}

#text-underline-button .more {
  padding-top: 8px;
}

#text-underline-button-bottom-line {
  width: 16px;
  height: 2px;
  transition: 0.25s;
}

#text-underline-button-bottom-line.middle {
  width: 16px;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
}

#text-underline-button:hover #text-underline-button-bottom-line {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  #text-underline-button .more {
    padding-top: 24px;
  }
}
