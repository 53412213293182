#feature-headline-subtitle {
  margin-top: 1%;
  margin-left: 5%;
  margin-right: 5%;
}

#feature-headline-marquee {
  white-space: nowrap;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  #feature-headline h1 {
    margin-left: 6px;
    margin-right: 6px;
  }

  #feature-headline-container p {
    margin-top: 5%;
    font-size: 3vh !important;
    line-height: 3vh !important;
    letter-spacing: 0.1vh;
  }
}

@media only screen and (min-width: 768px) {
  #feature-headline h1 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #feature-headline-subtitle {
    margin-left: 20%;
    margin-right: 20%;
  }

  #feature-headline-container p {
    margin-top: 1%;
    font-size: 2.9vh !important;
    line-height: 3vh !important;
    letter-spacing: 0.2vh;
  }
}

#feature-headline-scroll-content {
  display: inline-block;
}

#feature-headline-scroll-content h1 {
  display: inline-block;
}
