#feature-header-naow-logo {
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 16px;
  width: 60px;
  pointer-events: all;
  cursor: pointer;
}

#left-header {
  position: absolute;
  left: 0;
}

#right-header {
  position: absolute;
  right: 0;
}

#studio-close {
  margin-top: 14px;
  padding-top: 6px;
  padding-right: 28px;
  padding-bottom: 6px;
  pointer-events: all;
  border-right: 1px solid #ffffff44;
}

#studio-close p {
  text-transform: uppercase;
  letter-spacing: 1.8px;
  color: var(--naow_red);
  font-size: 14px;
  margin-left: 8px;
}

#studio-close-arrow {
  width: 10px;
  height: 8px;
}

#triangle-left {
  width: 0;
  height: 0;

  margin: 4px;
  border-top: 4px solid transparent;
  border-right: 8px solid var(--naow_red);
  border-bottom: 4px solid transparent;
}

#underline-wrapper {
  padding: 16px;
  padding-right: 0;
  width: 60px;
}

@media only screen and (max-width: 768px) {
  #studio-close p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  #studio-close {
    margin-top: 30px;
    padding-right: 34px;
    /* padding-left: 60px; */
  }

  #studio-close-arrow {
    width: 12px;
    height: 10px;
  }

  #underline-wrapper {
    padding: 32px;
  }

  #studio-close p {
    margin-left: 16px;
  }

  #right-header {
    right: 48px;
  }

  #feature-header-naow-logo {
    padding-left: 56px;
    padding-top: 42px;
    width: 68px;
    pointer-events: all;
    cursor: pointer;
  }
}
