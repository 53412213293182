#password-overlay {
  background-color: white;
  padding: 42px;
  border-radius: 10px;
  box-shadow: 0px 10px 40px #00000012;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#password-overlay h2 {
  font-family: "bebas-neue-pro";
  font-size: 45px;
  font-weight: bold;
}

#password-overlay p {
  font-family: "bebas-neue-pro";
  font-size: 18px;
  opacity: 0.5;
}

#login-form {
  padding-top: 50px;
  padding-bottom: 25px;
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-button {
  margin-left: 16px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  background-color: var(--naow_red);
}

#password-input {
  height: 30px;
  background-color: #f0f0f066;
  border: 1px solid var(--naow_dark_blue);
  border-radius: 5px;
  padding-left: 16px;
}
