#reference-subpage {
  position: relative;
  overflow: hidden;
}

#reference-subpage-header {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-content: flex-end;
  align-items: center;
}

#reference-main-img {
  position: absolute;
  left: 8%;
  bottom: 0;
  height: 35%;
  width: 60%;
}

#reference-main-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#references-link-button {
  margin-left: calc(90% - 112px);
  background-color: var(--naow_red);
  width: 80px;
  margin-top: 32px;
  padding: 8px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "bebas-neue-pro";
  color: white;
  border-radius: 32px;
}

#references-subtitle {
  margin-bottom: 6vh;
}

#reference-video-header {
  position: relative;
  width: 90%;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 10px 30px #00000033;
}

#reference-subpage p {
  color: white;
}

#references-detail-header h1 {
  font-family: var(--font-family-bebas-neue-pro);
  margin-left: 8px;
  margin-right: 8px;
  font-size: 9vh;
  height: 10vh;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 900;
}

#studio-underlined {
  text-decoration: underline;
  text-decoration-color: var(--naow_red);
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.references-outlined {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

.references-white {
  color: white;
}

#references-detail-header {
  display: flex;
  flex-direction: column;
}

#reference-text-block {
  position: relative;
  padding: 24px;
  background-image: url("/src/icons/ic_separator_studio.svg");
  min-height: 280px;
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: left;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-end;
  justify-content: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 4vw;
}

/* #reference-header-background {
  position: absolute;
  left: 0;
  top: 0;
} */

#reference-text-block h3 {
  font-style: italic;
  font-size: 36px;
  color: white;
  letter-spacing: 0;
}

#reference-text-block p {
  opacity: 0.8;
}

#reference-text-block #studio-line-horizontal {
  margin-top: 8px;
}

#reference-text-block-text {
  width: 90%;
}

#reference-challenge {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#reference-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

#reference-container.right {
  flex-direction: column-reverse;
}

#reference-item {
  width: 90%;
}

#reference-container video {
  width: 90vw;
}

@media only screen and (max-width: 768px) {
  #reference-text-block p {
    font-size: 18px;
  }
  #reference-text-block {
    width: 80vw;
  }

  #references-detail-header h1 {
    font-family: var(--font-family-bebas-neue-pro);
    margin-left: 8px;
    margin-right: 8px;
    font-size: 8vh;
    height: 10vh;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 900;
  }
}

@media only screen and (min-width: 768px) {
  #references-link-button {
    margin-left: calc(50% - 112px);
  }

  #reference-main-img {
    height: 75%;
    width: 25%;
  }
  #reference-subpage-header {
    flex-direction: column;
  }
  #references-detail-header {
    flex-direction: row;
  }
  #reference-video-header {
    width: 50%;
  }
  #reference-container {
    flex-direction: row;
  }
  #reference-container.right {
    flex-direction: row-reverse;
  }
  #reference-text-block {
    width: 480px;
  }
  #reference-item {
    width: 60%;
  }
  #reference-container video {
    width: 50vw;
  }
}
