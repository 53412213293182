#webinar {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#webinar h2 {
  font-family: "bebas-neue-pro";
  font-size: 10vh;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: 10vh;
  letter-spacing: normal;
  color: white;
  width: 80vw;
}

#webinar-video {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70vh;
}

#webinar-video video {
  position: relative;
  top: 0;
  object-fit: cover;
  object-position: top;
}

#webinar-video-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #142f4344;
}

#webinar-info {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

#webinar-info-desc {
  padding: 32px;
  text-align: left;
}

#webinar-form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#webinar-button {
  margin-top: 16px;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--naow_red);
  box-shadow: -5px 5px 25px #00000026;
  background-image: url("/src/icons//naow_logo_backdrop.svg");
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: center -10px;
}

#webinar li {
  margin-bottom: 8px;
}

#webinar-info p {
}

#teaser-modal {
  width: 90vw;
  /* height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

#teaser-close {
  position: absolute;
  max-width: 30px;
  max-height: 30px;
  min-height: 16px;
  min-width: 16px;
  width: 3vmin;
  height: 3vmin;
  top: 20px;
  right: 20px;
}

@media only screen and (min-width: 768px) {
  #teaser-modal {
    width: 90vw;
    height: 90vh;
  }

  #webinar-video {
    height: 50vh;
  }

  #webinar-info {
    flex-direction: row;
    justify-content: center;
    height: 100%;
    flex-direction: row;
  }

  #webinar-info-desc {
    width: 40%;
  }
  #webinar-form {
    width: 40%;
    margin-top: 32px;
  }
}
