#cookie-text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  #cookie-text-container {
    height: 80px;
  }
}

@media only screen and (min-width: 768px) {
  #cookie-text-container {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }
}
