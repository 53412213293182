@import url(https://use.typekit.net/rzq0pxl.css);
:root {
  --tag03: #ff3b60;
  --tag02: #ffb062;
  --tag01: #a462ff;
  --white: #ffffff;
  --beige: #e2dedb;
  --background: #e2dedb;
  --naow_dark_blue: #142f43;
  --naow_red: #f1485b;
  --transition-time-fast: 0.5s;
  /* Font/text values */
  --font-family-bebas-neue: bebas-neue;
  --font-family-bebas-neue-pro: bebas-neue-pro-expanded, sans-serif;
  --font-style-normal: normal;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-size-15: 15px;
  --font-size-18: 18px;
  --font-size-22: 22px;
  --font-size-25: 25px;
  --font-size-45: 45px;
  --character-spacing-0: 0px;
  --character-spacing-1-5: 1.5px;
  --character-spacing-2-5: 2.5px;
  --character-spacing-4-5: 4.5px;
  --character-spacing-1-8: 1.8px;
  --character-spacing-2-2: 2.2px;
  --line-spacing-22: 22px;
  --line-spacing-70: 70px;
  --line-spacing-88: 88px;
  --line-spacing-91: 91px;
  --text-transform-uppercase: uppercase;
}

/* Character Styles */
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

.outlined {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #142f43;
  -webkit-text-stroke: 1px var(--naow_dark_blue);
}

.outlined-background {
  -webkit-text-fill-color: #142F430D;
  -webkit-text-stroke: 1px #142f43;
  -webkit-text-stroke: 1px var(--naow_dark_blue);
}

.italic-accented {
  color: #f1485b;
  color: var(--naow_red);
  font-style: italic;
  /* font: italic; */
}

.text-shadow {
  text-shadow: 3px 3px 10px #00000010;
}

h5 {
  font-family: bebas-neue;
  font-family: var(--font-family-bebas-neue);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  font-size: 15px;
  font-size: var(--font-size-15);
  line-height: 91px;
  line-height: var(--line-spacing-91);
  letter-spacing: 1.5px;
  letter-spacing: var(--character-spacing-1-5);
  color: #142f43;
  color: var(--naow_dark_blue);
}
h3 {
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-family: var(--font-family-bebas-neue-pro);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  font-size: 22px;
  font-size: var(--font-size-22);
  letter-spacing: 2.2px;
  letter-spacing: var(--character-spacing-2-2);
  color: #142f43;
  color: var(--naow_dark_blue);
  text-transform: uppercase;
  text-transform: var(--text-transform-uppercase);
}
h2 {
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-family: var(--font-family-bebas-neue-pro);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  font-size: 45px;
  font-size: var(--font-size-45);
  line-height: 70px;
  line-height: var(--line-spacing-70);
  letter-spacing: 4.5px;
  letter-spacing: var(--character-spacing-4-5);
  color: #142f43;
  color: var(--naow_dark_blue);
  text-transform: uppercase;
  text-transform: var(--text-transform-uppercase);
}
h4.uppercase-black {
  font-family: var(--unnamed-font-family-bebas-neue-pro);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-15);
  line-height: var(--unnamed-line-spacing-70);
  letter-spacing: var(--unnamed-character-spacing-4-5);
  color: #142f43;
  color: var(--naow_dark_blue);
  text-transform: var(--unnamed-text-transform-uppercase);
}

h4.uppercase-white {
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-family: var(--font-family-bebas-neue-pro);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  font-size: var(--font-size-18);
  letter-spacing: 1.8px;
  letter-spacing: var(--character-spacing-1-8);
  color: #ffffff;
  color: var(--white);
  text-transform: uppercase;
  text-transform: var(--text-transform-uppercase);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}
p {
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-family: var(--font-family-bebas-neue-pro);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: normal;
  font-weight: var(--font-weight-normal);
  font-size: 18px;
  font-size: var(--font-size-18);
  line-height: 22px;
  line-height: var(--line-spacing-22);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #142f43;
  color: var(--naow_dark_blue);
}

body {
  margin: 0;
  background-color: #e2dedb;
  background-color: var(--background);
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: #142f4311;
}
/* 
body,
html {
  scroll-snap-type: block mandatory;
  scroll-snap-stop: always;
} */

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

input {
  /* font-family: "TradeGothic", sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}

button {
  border: none;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noscroll {
  overflow: hidden;
}

.overlow-y-scroll {
  overflow-y: scroll;
}

.BebasNeuePro_h1_red {
  font-family: "bebas-neue-pro";
  font-size: 180px;
  font-weight: 800;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--naow-red);
}
.BebasNeuePro_h1_light {
  font-family: "bebas-neue-pro";
  font-size: 180px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(20, 47, 67, 0.03);
}
.BebasNeuePro_h2_red {
  font-family: "bebas-neue-pro";
  font-size: 140px;
  font-weight: 800;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--naow-red);
}
.BebasNeuePro_h2_light {
  font-family: "bebas-neue-pro";
  font-size: 140px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(20, 47, 67, 0.05);
}
.BebasNeuePro_h3_light {
  font-family: "bebas-neue-pro";
  font-size: 130px;
  font-weight: 800;
  font-stretch: expanded;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  color: var(--white);
}
.BebasNeuePro_h4_light {
  font-family: "bebas-neue-pro";
  font-size: 100px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.BebasNeuePro_subtitle {
  font-family: "bebas-neue-pro";
  font-size: 25px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: 2.5px;
  color: var(--white);
}
.BebasNeuePro_button_light {
  font-family: "bebas-neue-pro";
  font-size: 23px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.BebasNeuePro_button_dark {
  font-family: "bebas-neue-pro";
  font-size: 23px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
}
.BebasNeuePro_p_thin_light {
  font-family: "bebas-neue-pro";
  font-size: 22px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
  color: var(--white);
}

.BebasNeuePro_p_thin_light p {
  font-family: "bebas-neue-pro";
  font-size: 22px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
  color: var(--white);
}

.BebasNeuePro_p_dark {
  font-family: "bebas-neue-pro";
  font-size: 22px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.2px;
}
.BebasNeuePro_p_light {
  font-family: "bebas-neue-pro";
  font-size: 20px;
  font-weight: normal;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--white);
}
.BebasNeuePro_h5_light {
  font-family: "bebas-neue-pro";
  font-size: 15px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--white);
}
.BebasNeuePro_h5_dark {
  font-family: "bebas-neue-pro";
  font-size: 15px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
}
.BebasNeuePro_footer {
  font-family: "bebas-neue-pro";
  font-size: 15px;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#modal-left {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#cookie-text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  #cookie-text-container {
    height: 80px;
  }
}

@media only screen and (min-width: 768px) {
  #cookie-text-container {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000022;
  z-index: 1000;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  overflow: hidden;
}

.overlay-container iframe {
  background-color: white;
  position: relative;
  width: 920px;
  max-width: 80vw;
  height: 746px;
  max-height: 90vh;
  border-radius: 16px;
  border: 0;
  box-shadow: 0px 8px 30px #00000055;
}

.overlay .overlay-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#talk-naow-button {
  height: 100%;
  width: 100px;
  margin-right: 5vw;
  margin-left: 5vw;
  align-items: center;
  background-color: var(--naow_red);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: 0.5s;
  pointer-events: all;
  box-shadow: -5px 5px 25px #00000026;
  background-image: url(/static/media/naow_logo_backdrop.c3d80ba4.svg);
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: center -10px;
}

#talk-naow-button h3 {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0px;
}

#talk-naow-extended {
  max-height: 0px;
  opacity: 0;
  padding-left: 16px;
  padding-right: 16px;
  transition: 0.5s;
}

#talk-naow-extended.expanded {
  max-height: 2000px;
  padding-bottom: 32px;
  opacity: 1;
}

#talk-naow-extended div {
  opacity: 0;
  transition: 0.15s;
  transition-delay: 0s;
}

#talk-naow-extended.expanded div {
  transition: 0.3s;
  transition-delay: 0.25s;
  opacity: 1;
}

#talk-naow-text {
  padding-top: 16px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 32px;
}

#talk-naow-text p {
  margin-top: 8px;
  color: white;
}

#book-appointment-button {
  width: 80%;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--naow_dark_blue);
}

#book-appointment-button p {
  padding: 20px;
  text-transform: uppercase;
}

#talk-naow-mailto {
  margin-top: 32px;
}

#talk-naow-line {
  width: 1px;
  height: 24px;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  #talk-naow-text p {
    text-overflow: hidden;
    white-space: nowrap;
  }
  #book-appointment-button p {
    text-overflow: hidden;
    white-space: nowrap;
  }
  #talk-naow-button.expanded {
    width: 90vw;
  }
}

@media only screen and (min-width: 768px) {
  #talk-naow-button {
    width: 230px;
    margin-right: 16px;
    margin-left: 16px;
  }

  #talk-naow-button h3 {
    padding: 32px;
    margin: 0px;
  }

  #talk-naow-extended {
    padding-left: 24px;
    padding-right: 24px;
  }
  #book-appointment-button {
    width: 150%;
  }
  #talk-naow-mailto p {
    font-size: 18px;
  }
  #talk-naow-mailto:hover p {
    color: var(--naow_dark_blue);
  }
}

#text-underline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

#text-underline-button {
  cursor: pointer;
}

#text-underline-button .more {
  padding-top: 8px;
}

#text-underline-button-bottom-line {
  width: 16px;
  height: 2px;
  transition: 0.25s;
}

#text-underline-button-bottom-line.middle {
  width: 16px;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
}

#text-underline-button:hover #text-underline-button-bottom-line {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  #text-underline-button .more {
    padding-top: 24px;
  }
}

#feature-header-naow-logo {
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 16px;
  width: 60px;
  pointer-events: all;
  cursor: pointer;
}

#left-header {
  position: absolute;
  left: 0;
}

#right-header {
  position: absolute;
  right: 0;
}

#studio-close {
  margin-top: 14px;
  padding-top: 6px;
  padding-right: 28px;
  padding-bottom: 6px;
  pointer-events: all;
  border-right: 1px solid #ffffff44;
}

#studio-close p {
  text-transform: uppercase;
  letter-spacing: 1.8px;
  color: var(--naow_red);
  font-size: 14px;
  margin-left: 8px;
}

#studio-close-arrow {
  width: 10px;
  height: 8px;
}

#triangle-left {
  width: 0;
  height: 0;

  margin: 4px;
  border-top: 4px solid transparent;
  border-right: 8px solid var(--naow_red);
  border-bottom: 4px solid transparent;
}

#underline-wrapper {
  padding: 16px;
  padding-right: 0;
  width: 60px;
}

@media only screen and (max-width: 768px) {
  #studio-close p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  #studio-close {
    margin-top: 30px;
    padding-right: 34px;
    /* padding-left: 60px; */
  }

  #studio-close-arrow {
    width: 12px;
    height: 10px;
  }

  #underline-wrapper {
    padding: 32px;
  }

  #studio-close p {
    margin-left: 16px;
  }

  #right-header {
    right: 48px;
  }

  #feature-header-naow-logo {
    padding-left: 56px;
    padding-top: 42px;
    width: 68px;
    pointer-events: all;
    cursor: pointer;
  }
}

#canvas-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: var(--beige);
}

#finish-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.25s;
}

#canvas-image {
  position: relative;
  width: 100%;
}
#canvas-image img {
  width: 100%;
  height: 100%;
}

.ViewPortHandler .aspect-ratio-box::after {
    content: "";
    display: flex; /* block */
    padding-top: 83.33%;  /* perfect 16:9 ratio -> (100:56.25) */
}
.video-image-hover {
  transition: 0.2s;
}

#feature-footer p {
  padding-bottom: 8px;
  padding-right: 32px;
  padding-left: 32px;
  opacity: 0.5;
  text-transform: uppercase;
}

#feature-headline-subtitle {
  margin-top: 1%;
  margin-left: 5%;
  margin-right: 5%;
}

#feature-headline-marquee {
  white-space: nowrap;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  #feature-headline h1 {
    margin-left: 6px;
    margin-right: 6px;
  }

  #feature-headline-container p {
    margin-top: 5%;
    font-size: 3vh !important;
    line-height: 3vh !important;
    letter-spacing: 0.1vh;
  }
}

@media only screen and (min-width: 768px) {
  #feature-headline h1 {
    margin-left: 8px;
    margin-right: 8px;
  }

  #feature-headline-subtitle {
    margin-left: 20%;
    margin-right: 20%;
  }

  #feature-headline-container p {
    margin-top: 1%;
    font-size: 2.9vh !important;
    line-height: 3vh !important;
    letter-spacing: 0.2vh;
  }
}

#feature-headline-scroll-content {
  display: inline-block;
}

#feature-headline-scroll-content h1 {
  display: inline-block;
}

#feature {
  position: relative;
}

section {
  position: relative;
  height: 100vh;
}

#feature-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#feature-header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
}

#feature-flagship-headline-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 62%;
  pointer-events: none;
}

#feature-footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#feature-scroll-indicator {
  position: fixed;
  right: 20px;
  top: 40%;
  height: 20%;
  display: flex;
  align-items: center;
}

#feature-email {
  position: fixed;
  left: 20px;
  top: 40%;
  height: 20%;
  display: flex;
  align-items: center;
  margin-left: -42px;
}

#feature-email:hover p {
  color: var(--naow_red);
}

#feature h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 11vmin;
  line-height: 11vmin;
  /* margin-bottom: 12px; */
  text-transform: uppercase;
  font-weight: 900;
}

.copy-block {
  opacity: 0;
}

.active {
  opacity: 1;
}

#feature-cut {
  position: absolute;
  flex-direction: row-reverse;
  width: 100%;
  bottom: 73%;
  pointer-events: all;
  z-index: 99;
}

#feature-cut-circle {
  width: 60px;
  height: 60px;
  background-color: var(--naow_red);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  box-shadow: -5px 5px 20px #0000001a;
}

#feature-cut-line {
  height: 1px;
  margin-left: -30px;
  width: 25%;
  min-width: 200px;
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 16px 1px;
  background-repeat: repeat-x;
  /* border-bottom: dashed 2px grey; */
  /* background-color: grey; */
}

#feature-cut-arrow-line {
  width: 12px;
  height: 2px;
  background-color: white;
}

#feature-cut-arrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 8px solid white;
  border-bottom: 6px solid transparent;
}

#mobile-scroll-indicator {
  position: fixed;
  bottom: 24px;
  left: calc(50% - 6px);
}

@media only screen and (min-width: 1064px) {
  #feature {
    /* defines the stroll intensity needed */
    /* height: 500vh; */
  }
  #feature h1 {
    font-size: 12vmin;
    line-height: 12vmin;
  }
  #feature-cut-line {
    margin-left: -40px;
  }
  #feature-cut-circle {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  #feature-cut-arrow-line {
    width: 18px;
    height: 2px;
  }
  #feature-flagship-headline-container {
    top: 70%;
    margin-bottom: unset;
  }
}

#scroll-indicator-arrow {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--naow_dark_blue);
}

#intro {
  background-color: var(--beige);
  pointer-events: all;
  overflow: none;
}

#intro-container {
  width: 90%;
  overflow: none;
}
#intro-container h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 10vw;
  height: 10vw;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
}

#intro-button {
  pointer-events: all;
  margin-top: 8vh;
  border-radius: 48px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
  background: var(--naow_red);
  border-radius: 16px;
  box-shadow: -5px 5px 25px #00000026;
  background-image: url(/static/media/naow_logo_backdrop.c3d80ba4.svg);
  background-size: 92px;
  background-repeat: no-repeat;
  background-position: center -8px;
}

#touch-intro {
  position: relative;
  height: 150vh;
  pointer-events: all;
}

#touch-intro-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#touch-intro-container h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 14vw;
  height: 14vw;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
}

#touch-intro-button {
  pointer-events: all;
  margin-top: 8vh;
  border-radius: 48px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  background: var(--naow_red);
  border-radius: 20px;
}

#intro-swipe {
  position: fixed;
  bottom: 48px;
  width: 60px;
  height: 60px;
  left: calc(50% - 30px);
}

/* TransitionRoute */
.page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transition: 500ms;
}

.page-enter {
  z-index: -1;
}

.page-exit {
  z-index: 2;
}

.slidemodal {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.slidemodal-enter {
  z-index: 2;
}

.page-exit.no-timeout {
  z-index: -2;
}

.full-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.gone {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.no-overflow {
  overflow: hidden;
}

.abs {
  position: absolute;
}

.zindex-2 {
  z-index: 2;
}

.zindex-10 {
  z-index: 10;
}

.button {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-10 {
  flex: 0 0 10%;
}

.flex-20 {
  flex: 0 0 20%;
}

.flex-25 {
  flex: 0 0 25%;
}

.flex-30 {
  flex: 0 0 30%;
}

.flex-35 {
  flex: 0 0 35%;
}

.flex-40 {
  flex: 0 0 40%;
}

.flex-50 {
  flex: 0 0 50%;
}

.flex-60 {
  flex: 0 0 60%;
}

.flex-65 {
  flex: 0 0 65%;
}

.flex-70 {
  flex: 0 0 70%;
}

.flex-75 {
  flex: 0 0 75%;
}

.flex-80 {
  flex: 0 0 80%;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: flex-end;
}

.closed {
  margin-left: -101% !important;
}

.width-0 {
  width: 0px;
}

.width-unset {
  width: unset;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.padding-16 {
  padding: 16px;
}

.red {
  background-color: red;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.uppercase {
  text-transform: uppercase;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  cursor: default;
}

/* ANIMATIONS */

.animated-25 {
  transition: 0.25s;
}

.animated-50 {
  transition: 0.5s;
}

.animated-100 {
  transition: 1s;
}

.fadeIn {
  opacity: 1;
  transition: 0.5s;
}

.fadeOut {
  opacity: 0;
  transition: 0.5s;
}

.fadeOut1s {
  opacity: 0;
  transition: 1s;
}

.transitionToOrigin {
  -webkit-transform: translate(0%);
          transform: translate(0%);
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.move-left {
  -webkit-transform: translateX(-250%);
          transform: translateX(-250%);
}

.move-right {
  -webkit-transform: translateX(250%);
          transform: translateX(250%);
}

#flagship-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#flagship-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

#flagship-intro h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 7vmax;
  height: 7vmax;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
  margin-left: 8px;
  margin-right: 8px;
}

#flagship-intro p {
  font-size: 24px;
  margin-top: 64px;
  margin-bottom: 8px;
  /* width: 75%; */
}

#flagship-button {
  pointer-events: all;
  margin-top: 8vh;
  border-radius: 48px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
  background: var(--naow_red);
  border-radius: 16px;
  box-shadow: -5px 5px 25px #00000026;
  background-image: url(/static/media/naow_logo_backdrop.c3d80ba4.svg);
  background-size: 92px;
  background-repeat: no-repeat;
  background-position: center -8px;
}

.flagship-intro-lottie {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  #flagship-intro-headers {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  #flagship-intro p {
    /* width: 50%; */
  }
}

#privacy-policy-container {
  padding: 32px;
  text-align: left;
}

#privacy-policy-container h1 {
  margin-top: 80px;
  margin-bottom: 60px;
  font-family: "bebas-neue-pro";
  font-size: 80px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#privacy-policy-container li {
  font-family: var(--font-family-bebas-neue-pro);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-22);
  letter-spacing: var(--character-spacing-0);
  color: var(--naow_dark_blue);
}
#header-background h2 {
    font-family: var(--font-family-bebas-neue-pro);
    font-size: 14vmin;
    height: 14vmin;
    overflow: visible;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 16px;
    letter-spacing: 0;
    transition: 0.5s;
  }
#reference-subpage {
  position: relative;
  overflow: hidden;
}

#reference-subpage-header {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-content: flex-end;
  align-items: center;
}

#reference-main-img {
  position: absolute;
  left: 8%;
  bottom: 0;
  height: 35%;
  width: 60%;
}

#reference-main-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#references-link-button {
  margin-left: calc(90% - 112px);
  background-color: var(--naow_red);
  width: 80px;
  margin-top: 32px;
  padding: 8px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "bebas-neue-pro";
  color: white;
  border-radius: 32px;
}

#references-subtitle {
  margin-bottom: 6vh;
}

#reference-video-header {
  position: relative;
  width: 90%;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 10px 30px #00000033;
}

#reference-subpage p {
  color: white;
}

#references-detail-header h1 {
  font-family: var(--font-family-bebas-neue-pro);
  margin-left: 8px;
  margin-right: 8px;
  font-size: 9vh;
  height: 10vh;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 900;
}

#studio-underlined {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--naow_red);
          text-decoration-color: var(--naow_red);
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.references-outlined {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

.references-white {
  color: white;
}

#references-detail-header {
  display: flex;
  flex-direction: column;
}

#reference-text-block {
  position: relative;
  padding: 24px;
  background-image: url(/static/media/ic_separator_studio.ddd3a9ba.svg);
  min-height: 280px;
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: left;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-end;
  justify-content: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 4vw;
}

/* #reference-header-background {
  position: absolute;
  left: 0;
  top: 0;
} */

#reference-text-block h3 {
  font-style: italic;
  font-size: 36px;
  color: white;
  letter-spacing: 0;
}

#reference-text-block p {
  opacity: 0.8;
}

#reference-text-block #studio-line-horizontal {
  margin-top: 8px;
}

#reference-text-block-text {
  width: 90%;
}

#reference-challenge {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#reference-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

#reference-container.right {
  flex-direction: column-reverse;
}

#reference-item {
  width: 90%;
}

#reference-container video {
  width: 90vw;
}

@media only screen and (max-width: 768px) {
  #reference-text-block p {
    font-size: 18px;
  }
  #reference-text-block {
    width: 80vw;
  }

  #references-detail-header h1 {
    font-family: var(--font-family-bebas-neue-pro);
    margin-left: 8px;
    margin-right: 8px;
    font-size: 8vh;
    height: 10vh;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 900;
  }
}

@media only screen and (min-width: 768px) {
  #references-link-button {
    margin-left: calc(50% - 112px);
  }

  #reference-main-img {
    height: 75%;
    width: 25%;
  }
  #reference-subpage-header {
    flex-direction: column;
  }
  #references-detail-header {
    flex-direction: row;
  }
  #reference-video-header {
    width: 50%;
  }
  #reference-container {
    flex-direction: row;
  }
  #reference-container.right {
    flex-direction: row-reverse;
  }
  #reference-text-block {
    width: 480px;
  }
  #reference-item {
    width: 60%;
  }
  #reference-container video {
    width: 50vw;
  }
}

#references {
  height: 300vh;
}

#references-headline-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 95vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

#reference-headline {
  display: flex;
  flex-direction: column;
  text-shadow: 10px 10px 80px #00000015;
  transition: 0.2s;
  margin-bottom: 1vh;
  margin-top: 1vh;
  cursor: pointer;
  pointer-events: all;
}

#references-canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: -1; */
}

#reference-headline:hover {
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}

#reference-headline h2 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 10vw;
  height: 10vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 900;
}

#build {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  left: 0;
  right: 0;
}

#feature-email {
  z-index: 2;
}

#feature-email:hover p {
  color: var(--naow_red);
}

#build-appointment {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
}

#build-learn-more {
  align-self: center;
  width: 100%;
  pointer-events: all;
}

#references-back-button {
  position: absolute;
  top: 60px;
  left: 32px;
  z-index: 3;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#references-back-button #feature-cut-circle {
  width: 40px;
  height: 40px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  background-color: var(--naow_dark_blue);
}

#references-back-button #feature-cut-arrow-line {
  width: 8px;
}

#references-back-button #feature-cut-arrow {
  border-top: 4px solid transparent;
  border-left: 6px solid white;
  border-bottom: 4px solid transparent;
}

#references-back-button p {
  margin-top: 4px;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #00000011;
}

#build p {
  letter-spacing: 1.5px;
}

@media only screen and (max-height: 650px) {
  #build p {
    font-size: 17px;
  }

  #build #book-appointment-button p {
    font-size: 18px;
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  #references-container {
    position: fixed;
    left: 0;
    right: 0;
  }

  #build {
    position: absolute;
    bottom: 0;
  }

  #build #book-appointment-button {
    margin-bottom: 24px;
  }

  #build-learn-more {
    margin-bottom: 3vh;
  }

  #build-learn-more p {
    padding-left: 32px;
    padding-right: 32px;
  }

  #keynote-underline-wrapper {
    padding: 16px;
  }
}

@media only screen and (min-width: 768px) {
  #references-headline-container {
    height: 100vh;
  }

  #references-back-button {
    top: 80px;
    left: 68px;
  }

  #references-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #build {
    position: absolute;
    justify-content: space-around;
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
  }
  #build-appointment {
    width: 50%;
    grid-row: 2;
    grid-column: 2 / 3;
    min-width: 200px;
    width: 15vw;
    max-width: 200px;
  }

  #build-learn-more {
    margin-top: 36px;
    grid-row: 3;
    grid-column: 3 / 6;
  }

  #reference-headline {
    flex-direction: row;
    margin-bottom: 2vh;
    margin-top: 2vh;
  }

  #reference-headline h2 {
    font-size: 5vw;
    height: 5vw;
    margin-right: 16px;
  }
  #keynote-underline-wrapper {
    padding: 24px;
  }
}

#loader {
  border: 6px solid #f3f3f388;
  border-top: 6px solid #555555cc;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#modal-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: #00000033;
  z-index: 1000;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

#password-overlay {
  background-color: white;
  padding: 42px;
  border-radius: 10px;
  box-shadow: 0px 10px 40px #00000012;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#password-overlay h2 {
  font-family: "bebas-neue-pro";
  font-size: 45px;
  font-weight: bold;
}

#password-overlay p {
  font-family: "bebas-neue-pro";
  font-size: 18px;
  opacity: 0.5;
}

#login-form {
  padding-top: 50px;
  padding-bottom: 25px;
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-button {
  margin-left: 16px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  background-color: var(--naow_red);
}

#password-input {
  height: 30px;
  background-color: #f0f0f066;
  border: 1px solid var(--naow_dark_blue);
  border-radius: 5px;
  padding-left: 16px;
}

#web-exp-container {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

#web-exp-image-container {
  height: 30vh;
  background-color: #f1f1f1;
}

#web-exp-image {
  max-height: 20vh;
  max-width: 80%;
  box-shadow: 3px 3px 16px #00000022;
  margin: 8%;
}

#web-exp-overview {
  padding: 24px;
  padding-top: 0px;
  background-color: #f1f1f1;
}

#web-exp-details {
  text-align: start;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
}

#euro-symbol {
  background-color: #d9d9d9;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  margin-right: 8px;
}

#web-exp-overview p {
  font-family: "bebas-neue-pro";
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

#euro-symbol p {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

#web-exp-visit-site {
  border-radius: 100px;
  cursor: pointer;
  background-color: var(--naow_red);
}

#web-exp-visit-site h4 {
  font-family: "bebas-neue-pro";
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

#web-exp-details h3 {
  font-family: "bebas-neue-pro";
  margin-bottom: 8px;
}

#web-exp-details p {
  font-family: "bebas-neue-pro";
  font-size: 16px;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 24px;
}

#web-exp-divider {
  height: 1px;
  background-color: whitesmoke;
}

#showcases {
  background-color: var(--beige);
}

#experiences-container {
  display: grid;
  grid-template-columns: 80%;
  grid-row-gap: 16px;
  row-gap: 16px;
  grid-column-gap: 32px;
  -webkit-column-gap: 32px;
          column-gap: 32px;
}

#showcase-headers {
  -webkit-mask-image: radial-gradient(
    closest-side,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0.25)
  );
          mask-image: radial-gradient(
    closest-side,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0.25)
  );
}

#showcase-headers h2:hover {
  -webkit-text-fill-color: unset !important;
  -webkit-text-stroke: unset !important;
  color: var(--naow_red) !important;
  font-style: italic !important;
}

#showcase-discover {
  position: absolute;
  top: 95%;
  width: 200px;
  text-align: center;
  left: calc(50% - 100px);
}

#showcase-gradient {
  height: 5vh;
  margin-top: -5vh;
  background-image: linear-gradient(to top, transparent, var(--beige));
}

#showcases-header-top {
  position: absolute;
  top: 40px;
  left: 80px;
  z-index: 2;
  display: flex;
  width: 180px;
  justify-content: space-between;
}

#showcase-discover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#showcase-discover p,
#showcases-header-top p {
  font-family: "bebas-neue-pro";
  font-weight: bold;
}

#showcase-line-down {
  width: 1px;
  height: 32px;
  background-color: var(--naow_dark_blue);
}

@media only screen and (min-width: 768px) {
  #experiences-container {
    grid-template-columns: 40% 40%;
  }
}

@media only screen and (min-width: 1024px) {
  #experiences-container {
    grid-template-columns: 25% 25% 25%;
  }
}

#studio-page {
  position: relative;
  background-color: var(--naow_dark_blue);
  width: 100%;
  overflow-y: scroll;
  z-index: 2;
  overflow-x: hidden;
}

#studio-intro {
  position: relative;
  height: 100vh;
}

#studio-main-headline {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 44vh;
}

#studio-find-out {
  position: absolute;
  width: 100%;
  top: 82vh;
  /* bottom: env(safe-area-inset-bottom, 180px); not working with safari 15*/
  display: flex;
  flex-direction: column;
  align-items: center;
}

#studio-page h1 {
  font-family: var(--font-family-bebas-neue-pro);
  margin-left: 8px;
  margin-right: 8px;
  font-size: 9vh;
  height: 10vh;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 900;
}

#studio-underlined {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--naow_red);
          text-decoration-color: var(--naow_red);
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.studio-white {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
}

#studio-naow {
  color: white;
}

#studio-page p {
  color: white;
}

#down-stroke {
  margin-top: 8px;
  height: 24px;
  width: 1px;
  background-color: white;
}

#studio-header {
  position: fixed;
  width: 100%;
  top: 0;
}

.studio-page-lottie {
  width: 120vw;
}

#info-container {
  text-align: center;
}

.info-container-background {
  padding: 24px;
  background-image: url(/static/media/ic_separator_studio.ddd3a9ba.svg);
  min-height: 280px;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

#studio-info p,
#studio-info-trinity p {
  width: 100%;
  margin-bottom: 8px;
}

#studio-info-trinity svg {
  margin-top: -10vh;
  margin-bottom: -10vh;
}

#studio-line-down {
  margin-top: 8vh;
  height: 50vh;
  width: 1px;
  background-color: var(--naow_red);
}

#arrow-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 8px solid var(--naow_red);
}

#studio-flagship-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

#studio-end {
  margin-top: 8vh;
  margin-bottom: 8vh;
}

#studio-flagship-explained {
  background-image: url(/static/media/ic_separator_studio.ddd3a9ba.svg);
  background-size: 160px;
  background-repeat: no-repeat;
  background-position: left;
  text-align: left;
  margin-top: -40px;
  padding-left: 80px;
  padding-top: 40px;
  padding-bottom: 120px;
}

#studio-line-horizontal {
  margin-top: 8vh;
  margin-bottom: 8px;
  width: 50px;
  height: 1px;
  background-color: var(--naow_red);
}

#studio-team {
  background-color: var(--background);
}

#studio-team svg {
  margin-left: -1px;
  margin-top: -1px;
}

#studio-end-headline-part1 {
  display: flex;
}

@media only screen and (max-width: 768px) {
  #studio-main-headline {
    flex-direction: column;
    align-items: center;
    width: 90%;
    left: 5%;
    top: 33%;
  }
  #info-container {
    padding: 24px;
  }
  #studio-page p {
    font-size: 20px;
  }
  #studio-end-headline-part1 {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  #studio-find-out {
    top: unset;
    bottom: 8px;
  }

  #studio-page h1 {
    font-size: 8vmin;
    height: 8vmin;
  }

  #studio-info p,
  #studio-info-trinity p {
    width: 60%;
  }
  .info-container-background {
    padding: 48px;
  }

  .studio-page-lottie {
    width: 50vw;
  }
  #studio-info,
  #studio-info-trinity {
    margin-top: 10vh;
  }
  #studio-underlined {
    text-underline-offset: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  #studio-flagship-container {
    flex-direction: row;
    width: 60%;
  }
  #studio-page h1 {
    font-size: 12vmin;
    height: 12vmin;
  }
}

#webinar {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#webinar h2 {
  font-family: "bebas-neue-pro";
  font-size: 10vh;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: 10vh;
  letter-spacing: normal;
  color: white;
  width: 80vw;
}

#webinar-video {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70vh;
}

#webinar-video video {
  position: relative;
  top: 0;
  object-fit: cover;
  object-position: top;
}

#webinar-video-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #142f4344;
}

#webinar-info {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

#webinar-info-desc {
  padding: 32px;
  text-align: left;
}

#webinar-form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#webinar-button {
  margin-top: 16px;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--naow_red);
  box-shadow: -5px 5px 25px #00000026;
  background-image: url(/static/media/naow_logo_backdrop.c3d80ba4.svg);
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: center -10px;
}

#webinar li {
  margin-bottom: 8px;
}

#webinar-info p {
}

#teaser-modal {
  width: 90vw;
  /* height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

#teaser-close {
  position: absolute;
  max-width: 30px;
  max-height: 30px;
  min-height: 16px;
  min-width: 16px;
  width: 3vmin;
  height: 3vmin;
  top: 20px;
  right: 20px;
}

@media only screen and (min-width: 768px) {
  #teaser-modal {
    width: 90vw;
    height: 90vh;
  }

  #webinar-video {
    height: 50vh;
  }

  #webinar-info {
    flex-direction: row;
    justify-content: center;
    height: 100%;
    flex-direction: row;
  }

  #webinar-info-desc {
    width: 40%;
  }
  #webinar-form {
    width: 40%;
    margin-top: 32px;
  }
}

