#canvas-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: var(--beige);
}

#finish-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.25s;
}

#canvas-image {
  position: relative;
  width: 100%;
}
#canvas-image img {
  width: 100%;
  height: 100%;
}
