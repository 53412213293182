#flagship-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#flagship-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

#flagship-intro h1 {
  font-family: var(--font-family-bebas-neue-pro);
  font-size: 7vmax;
  height: 7vmax;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
  margin-left: 8px;
  margin-right: 8px;
}

#flagship-intro p {
  font-size: 24px;
  margin-top: 64px;
  margin-bottom: 8px;
  /* width: 75%; */
}

#flagship-button {
  pointer-events: all;
  margin-top: 8vh;
  border-radius: 48px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
  background: var(--naow_red);
  border-radius: 16px;
  box-shadow: -5px 5px 25px #00000026;
  background-image: url("/src/icons//naow_logo_backdrop.svg");
  background-size: 92px;
  background-repeat: no-repeat;
  background-position: center -8px;
}

.flagship-intro-lottie {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  #flagship-intro-headers {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  #flagship-intro p {
    /* width: 50%; */
  }
}
