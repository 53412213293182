/* TransitionRoute */
.page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transition: 500ms;
}

.page-enter {
  z-index: -1;
}

.page-exit {
  z-index: 2;
}

.slidemodal {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.slidemodal-enter {
  z-index: 2;
}

.page-exit.no-timeout {
  z-index: -2;
}
