@import url("https://use.typekit.net/rzq0pxl.css");

:root {
  --tag03: #ff3b60;
  --tag02: #ffb062;
  --tag01: #a462ff;
  --white: #ffffff;
  --beige: #e2dedb;
  --background: #e2dedb;
  --naow_dark_blue: #142f43;
  --naow_red: #f1485b;
  --transition-time-fast: 0.5s;
  /* Font/text values */
  --font-family-bebas-neue: bebas-neue;
  --font-family-bebas-neue-pro: bebas-neue-pro-expanded, sans-serif;
  --font-style-normal: normal;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-size-15: 15px;
  --font-size-18: 18px;
  --font-size-22: 22px;
  --font-size-25: 25px;
  --font-size-45: 45px;
  --character-spacing-0: 0px;
  --character-spacing-1-5: 1.5px;
  --character-spacing-2-5: 2.5px;
  --character-spacing-4-5: 4.5px;
  --character-spacing-1-8: 1.8px;
  --character-spacing-2-2: 2.2px;
  --line-spacing-22: 22px;
  --line-spacing-70: 70px;
  --line-spacing-88: 88px;
  --line-spacing-91: 91px;
  --text-transform-uppercase: uppercase;
}

/* Character Styles */
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

.outlined {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--naow_dark_blue);
}

.outlined-background {
  -webkit-text-fill-color: #142F430D;
  -webkit-text-stroke: 1px var(--naow_dark_blue);
}

.italic-accented {
  color: var(--naow_red);
  font-style: italic;
  /* font: italic; */
}

.text-shadow {
  text-shadow: 3px 3px 10px #00000010;
}

h5 {
  font-family: var(--font-family-bebas-neue);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-15);
  line-height: var(--line-spacing-91);
  letter-spacing: var(--character-spacing-1-5);
  color: var(--naow_dark_blue);
}
h3 {
  font-family: var(--font-family-bebas-neue-pro);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-22);
  letter-spacing: var(--character-spacing-2-2);
  color: var(--naow_dark_blue);
  text-transform: var(--text-transform-uppercase);
}
h2 {
  font-family: var(--font-family-bebas-neue-pro);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-45);
  line-height: var(--line-spacing-70);
  letter-spacing: var(--character-spacing-4-5);
  color: var(--naow_dark_blue);
  text-transform: var(--text-transform-uppercase);
}
h4.uppercase-black {
  font-family: var(--unnamed-font-family-bebas-neue-pro);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-15);
  line-height: var(--unnamed-line-spacing-70);
  letter-spacing: var(--unnamed-character-spacing-4-5);
  color: var(--naow_dark_blue);
  text-transform: var(--unnamed-text-transform-uppercase);
}

h4.uppercase-white {
  font-family: var(--font-family-bebas-neue-pro);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-18);
  letter-spacing: var(--character-spacing-1-8);
  color: var(--white);
  text-transform: var(--text-transform-uppercase);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}
p {
  font-family: var(--font-family-bebas-neue-pro);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-22);
  letter-spacing: var(--character-spacing-0);
  color: var(--naow_dark_blue);
}

body {
  margin: 0;
  background-color: var(--background);
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: #142f4311;
}
/* 
body,
html {
  scroll-snap-type: block mandatory;
  scroll-snap-stop: always;
} */

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

input {
  /* font-family: "TradeGothic", sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}

button {
  border: none;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noscroll {
  overflow: hidden;
}

.overlow-y-scroll {
  overflow-y: scroll;
}
