#header-background h2 {
    font-family: var(--font-family-bebas-neue-pro);
    font-size: 14vmin;
    height: 14vmin;
    overflow: visible;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 16px;
    letter-spacing: 0;
    transition: 0.5s;
  }