#privacy-policy-container {
  padding: 32px;
  text-align: left;
}

#privacy-policy-container h1 {
  margin-top: 80px;
  margin-bottom: 60px;
  font-family: "bebas-neue-pro";
  font-size: 80px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-stretch: semi-expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#privacy-policy-container li {
  font-family: var(--font-family-bebas-neue-pro);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-22);
  letter-spacing: var(--character-spacing-0);
  color: var(--naow_dark_blue);
}